import './style.css';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';

Alpine.plugin(intersect)

/* dev only */

window.addEventListener('beforeunload', () => {
  localStorage.setItem('scrollPosition', window.scrollY);
});

// Restore scroll position after the page loads
window.addEventListener('load', () => {
  const scrollPosition = localStorage.getItem('scrollPosition');
  if (scrollPosition !== null) {
    window.scrollTo({top: parseInt(scrollPosition, 10), left: 0, behavior:"smooth"});
    localStorage.removeItem('scrollPosition'); // Clean up
  }
});

/* dev only */

window.Alpine = Alpine;

Alpine.store('decoHeightsStore', {
  init(){
    this.header_height= document.getElementById('header').offsetHeight;
    this.content_height= document.getElementById('content').offsetHeight;
    this.footer_height= document.getElementById('footer').offsetHeight;
  },

  header_height: document.getElementById('header').offsetHeight,
  content_height: document.getElementById('content').offsetHeight,
  footer_height: document.getElementById('footer').offsetHeight,
  window_width:window.innerWidth,

  decoHeights(ratio) {
    return this.header_height+this.content_height+this.footer_height-parseInt(window.innerHeight*ratio);
  }
});

Alpine.store('windowSize',{
  window_width:window.innerWidth,

  init(){
    this.handleResize = this.handleResize.bind(this);
    this.window_width = window.innerWidth;
    window.addEventListener("resize", this.handleResize);
    this.handleResize;
  },

  handleResize(){
    this.window_width = window.innerWidth;
  }
})

document.addEventListener('DOMContentLoaded', async () => {
  let module = document.getElementById("content").dataset.module;

  if (module) {
    const modules = import.meta.glob('./*.js');
    
    const loadModule = async (moduleName) => {
      if (modules[`./${moduleName}.js`]) {
        return await modules[`./${moduleName}.js`]();
      } else {
        console.error(`Module './${moduleName}.js' not found.`);
      }
    };

    // Load and register the Alpine.js module
    const importedModule = await loadModule(module);
    if (importedModule) {
      Alpine.data('contact', importedModule.default);
    }
  }

  Alpine.data('gallery', (imagesFromBackend) => ({
    images: imagesFromBackend,
    selectedImage: imagesFromBackend.length ? imagesFromBackend[0] : '',
    currentIndex: 0,
    isLoading: false,
    loaded: false,
    
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
        this.selectedImage = this.images[this.currentIndex];
      }
    },
    
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.selectedImage = this.images[this.currentIndex];
      }
    },

    onImageLoad() {
      this.isLoading = false;
    }
  }));

  Alpine.start();
});

